import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import Icon from "../../../../../components/Icon/Icon";
import Input from "../../../../../components/Input";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import axios from "axios";
import {
  addNewMosque,
  entityApprove,
  entityDecline,
  setMosqueDetails,
  updateAddedNewFlag,
  updateMosque,
} from "../../../adminAction";
import { DatePicker } from "antd";
import moment from "moment";
import {
  getPaymentMethodOption,
  showNotification,
} from "../../../../../util/Utility";
import Dropdown from "../../../../../components/Dropdown";
import styled from "styled-components";
import dayjs from "dayjs";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import {
  UPDATE_APPROVAL_STATUS,
  UPDATE_DECLINE_STATUS,
} from "../../../adminActionType";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { fetchCities, fetchCountries } from "../../../../Mosque/mosqueAction";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { width } from "styled-system";
import PdfUploader from "../../../../../components/PdfUploader/PdfUploader";

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;

const paymentMethodOption = getPaymentMethodOption();

const MosqueForm = ({
  detailsMosque = null,
  entityId = null,
  approvalId = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [details, setDetails] = useState({});
  const [isDecline, setIsDecline] = useState(false);
  const [isMerchantDetails, setIsMerchantDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidMID, setIsValidMID] = useState(false);
  const { t } = useTranslation();
  const isFetchingCountries = useSelector(
    (state) => state.mosqueReducer.isFetchingCountries
  );
  const isFetchingCities = useSelector(
    (state) => state.mosqueReducer.isFetchingCities
  );
  const isApprovedApproval = useSelector(
    (state) => state.adminReducer.isApprovedApproval
  );
  const isApprovalDecline = useSelector(
    (state) => state.adminReducer.isApprovalDecline
  );
  const mosqueDetails = useSelector(
    (state) => state.adminReducer.mosqueDetails
  );
  const plan_list = useSelector((state) => state.adminReducer.plans?.plan_list);
  const isAddedNew = useSelector((state) => state.adminReducer.isAddedNew);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedPdfsLink, setUploadedPdfsLink] = useState([]);

  useEffect(() => {
    if (detailsMosque) {
      const data = {};
      data.entity_name = detailsMosque?.name;
      data.entity_address = detailsMosque?.address;
      data.entity_pincode = detailsMosque?.pincode;
      data.entity_country = detailsMosque?.country;
      data.paymentGatewayMode = detailsMosque?.paymentGatewayMode;
      data.entity_city = detailsMosque?.city;
      data.entity_website_key = detailsMosque?.entity_merchant_id;
      data.entity_secret_key = detailsMosque?.entity_merchant_key;
      data.entity_merchant_Id = detailsMosque?.entity_merchant_Id;
      data.contact_name = detailsMosque?.primary_user_id?.contact_name;
      data.contact_email = detailsMosque?.primary_user_id?.contact_email;
      data.contact_phone = detailsMosque?.primary_user_id?.contact_phone;
      data.country_code = detailsMosque?.primary_user_id?.country_code;
      data.contact_address = detailsMosque?.primary_user_id?.address;
      data.payment_method = detailsMosque?.organisation_id?.payment_method;
      data.isValidMID = detailsMosque?.isValidMID;
      data.saas_subscription_plan_id =
        detailsMosque?.organisation_id?.saas_subscription_plan_id;
      data.membership_date =
        detailsMosque?.organisation_id?.membership_date ??
        detailsMosque?.organisation_id?.created_at;
      if (
        data.entity_merchant_Id?.length > 0 &&
        data.entity_secret_key?.length > 0 &&
        data.entity_website_key?.length > 0
      )
        setIsMerchantDetails(true);
      if (!isValidMID) setIsValidMID(detailsMosque?.isValidMID);
      setDetails(data);
    } else {
      setDetails(mosqueDetails);
    }
  }, [detailsMosque, mosqueDetails]);

  const formValidation = () => {
    return (
      !!details?.entity_name &&
      !!details?.entity_address &&
      !!details?.entity_pincode &&
      !!details?.entity_country &&
      !!details?.entity_city &&
      !!details?.contact_name &&
      !!details?.contact_email &&
      !!details?.contact_phone &&
      !!details?.country_code &&
      !!details?.membership_date &&
      !!details?.paymentGatewayMode
    );
    // !!details?.entity_merchant_id &&
    // !!details?.entity_merchant_key
  };
  useEffect(() => {
    if (details) {
      setIsNext(formValidation());
    }
  }, [details]);

  const handleChangeUser = (keyName, keyValue) => {
    if (keyName === "contact_email") {
      keyValue = keyValue.toLowerCase();
    }

    dispatch(setMosqueDetails(keyName, keyValue));

    if (keyName === "entity_secret_key" || keyName === "entity_website_key")
      setIsValidMID(false);
    if (entityId && approvalId) {
      if (keyName === "entity_merchant_Id" && detailsMosque) {
        detailsMosque.entity_merchant_Id = keyValue;
      }
      if (keyName === "entity_secret_key" && detailsMosque) {
        detailsMosque.entity_merchant_key = keyValue;
      }
      if (keyName === "entity_website_key" && detailsMosque) {
        console.log(" merchant -----> ", keyName, keyValue);
        detailsMosque.entity_merchant_id = keyValue;
      }
    }
  };

  const optionsPlan = () => {
    let listValues = [];
    if (plan_list) {
      const list = plan_list?.forEach((reply, index) => {
        listValues.push({
          ...reply,
          label: reply?.plan_name,
          value: reply?._id,
        });
      });
    }
    return listValues;
  };

  const handleAddMosque = () => {
    dispatch(addNewMosque(true, "SIGN_UP",uploadedPdfsLink));
  };

  const handleAddNew = () => {
    dispatch(updateAddedNewFlag(false));
    window.location.href = "/admin/mosques";
  };

  useEffect(() => {
    if (isApprovalDecline) {
      dispatch({
        type: UPDATE_DECLINE_STATUS,
        isApprovalDecline: false,
      });
      setDetails(mosqueDetails);
      setIsDecline(false);
      navigate("/admin/mosques");
    }
  }, [isApprovalDecline]);
  useEffect(() => {
    dispatch(fetchCountries());
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && approvalId) {
        window.location.reload();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const handleApproval = () => {
    dispatch({
      type: UPDATE_APPROVAL_STATUS,
      isApprovedApproval: false,
    });

    window.location.href = `/admin/mosques`;
  };

  const handleClickDecline = () => {
    if (approvalId) {
      dispatch(entityDecline(approvalId));
    }
  };

  const handleClickApproval = () => {
    console.log(details);
    if (entityId && approvalId) {
      dispatch(entityApprove(entityId, approvalId, details,uploadedPdfsLink));
      setIsNext(formValidation());
    }
  };

  console.log(" mosque detail -------------------> ", details);
  const handlePayment = async (values) => {
    try {
      const paymentPayload = {
        userId: uuidv4(),
        paymentMode: "manual",
        paymentMethod: "ideal",
        amount: 0.01,
        subscriptionId: uuidv4(),
        paymentId: uuidv4(),
        entityId: entityId,
        merchantId: details.entity_website_key,
        merchantKey: details.entity_secret_key,
        buckarooMode: details.paymentGatewayMode
      };
      console.log(" payment payload ", paymentPayload);

      setIsLoading(true);
      const baseUrl =
        process.env.REACT_APP_STAGE === "development"
          ? "apidev.mosqapp.com"
          : "api.mosqapp.com";

      const res = await axios.post(
        `https://${baseUrl}/admin/test-transaction?approvalId=${approvalId}`,
        paymentPayload
      );
      console.log("payment-->", res.data);
      setIsLoading(false);
      const redirectUrl = res.data?.requiredAction?.redirectURL;
      window.open(redirectUrl);
      if (redirectUrl) {
        // setIsValidMID(true)
        showNotification("success", "Successfully validated");
      }
    } catch (error) {
      console.log(error);
      showNotification("error", "Incorrect Merchant-Id or Merchant Key");
      setIsLoading(false);
      if (!approvalId) {
        handleChangeUser("entity_secret_key", "");
        handleChangeUser("entity_website_key", "");
      }
    }
  };

  const getRenderButton = () => {
    if (detailsMosque) {
      return (
        <Box display="flex" justifyContent="center">
          <Box>
            <Button
              type="outlineColor"
              text={t("Moskee afwijzen")}
              borderRadius="66px"
              minWidth="200px"
              mt="4"
              mx="auto"
              height="50px"
              onClick={() => setIsDecline(true)}
            />
          </Box>
          <Box ml="15px">
            <Button
              //  type={(!details.entity_merchant_id || !details.entity_merchant_key)?"disabled":"primary"}
              text={t("Moskee goedkeuren")}
              borderRadius="66px"
              minWidth="200px"
              mt="4"
              mx="auto"
              height="50px"
              onClick={() => handleClickApproval()}
            />
          </Box>
        </Box>
      );
    } else {
      return (
        <Button
          type={!isNext ? "disabled" : "primary"}
          text={t("Gegevens opslaan")}
          borderRadius="66px"
          width="15%"
          mt="4"
          mx="auto"
          height="60px"
          onClick={() => handleAddMosque()}
        />
      );
    }
  };

  const handlePdfUpload = async () => {
    try {
      const formData = new FormData();
      setIsUploading(true);
      const baseUrl =
        process.env.REACT_APP_STAGE === "development"
          ? "apidev.mosqapp.com"
          : "api.mosqapp.com";

      selectedFiles.forEach((file, index) => {
        formData.append(`${file.name}`, file);
      });

      fetch(`${process.env.REACT_APP_API_URL}file/upload/autopay-pdfs`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to upload PDF");
          }
          return response.json();
        })
        .then((data) => {
          setIsUploading(false);
          showNotification("success", "Files uploaded successfully");
          console.log("PDF uploaded successfully:", data.data?.link);
          const pdfArray = data.data?.link.split(',')
          setUploadedPdfsLink(pdfArray);
          // setSelectedFiles([])
        })
        .catch((error) => {
          setIsUploading(false);
          console.error("Error uploading PDF:", error);
        });
    } catch (error) {
      console.log(error);
      setIsUploading(false);
      showNotification("error", "error uploading pdf(s) ...");
    }
  };

  return (
    <div className="add_form form_width">
      <div className="mosque-form p-2" style={{ paddingRight: "80px" }}>
        <h2>{t("Moskee toevoegen")}</h2>
        <div className="row p-2">
          <div className="col-lg-6" style={{ paddingRight: "40px" }}>
            <label>
              {t("Naam Moskee")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details?.entity_name}
              onChange={(e) => handleChangeUser("entity_name", e.target.value)}
              type="text"
              placeholder={t("Voer Naam Moskee In")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6" style={{ paddingRight: "40px" }}>
            <label>
              {t("Adres Moskee")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.entity_address}
              onChange={(e) =>
                handleChangeUser("entity_address", e.target.value)
              }
              type="text"
              placeholder={t("Voer Adres Moskee In")}
              className="input-box"
            />
          </div>

          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            <label>
              {t("Postcode")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.entity_pincode}
              onChange={(e) =>
                handleChangeUser("entity_pincode", e.target.value)
              }
              type="text"
              placeholder={t("Voer postcode in")}
              className="input-box"
            />
          </div>
          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            {!countries?.length ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label>
                  {t("Land Moskee")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Dropdown
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={t("Selecteer Land")}
                  color="#1a1a1aad"
                  fontWeight="500"
                  width="100%"
                  height="54px"
                  Icon="none"
                  selectedValue={details?.entity_country}
                  onOptionClick={(item) => {
                    handleChangeUser("entity_country", item?.value);
                    if (details?.entity_country !== item?.value) {
                      dispatch(fetchCities(item?.value));
                      handleChangeUser("entity_city", "");
                    }
                  }}
                  options={countries}
                />
              </>
            )}
          </div>
          <div
            className="col-lg-6 customDatePicker"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            <label>
              {t("Lidmaatschap sinds")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <br></br>
            <DatePickerBox>
              <DatePicker
                defaultValue={dayjs()}
                value={dayjs(details?.membership_date, "YYYY-MM-DD")}
                onChange={(value) => handleChangeUser("membership_date", value)}
              />
            </DatePickerBox>
          </div>
          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            {isFetchingCities ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label>
                  {t("Stad Moskee")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Dropdown
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={t("Selecteer stad")}
                  color="#1a1a1aad"
                  fontWeight="500"
                  width="100%"
                  height="54px"
                  Icon="none"
                  selectedValue={details?.entity_city}
                  onOptionClick={(item) =>
                    handleChangeUser("entity_city", item?.value)
                  }
                  options={cities}
                />
              </>
            )}
          </div>
          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            <label>
              {t("beheerder Naam")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder={t("Voer beheerdersnaam in")}
              className="input-box"
            />
          </div>
          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            <label>
              {t("E-mailadres")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder={t("Voer uw E-mailadres In")}
              className="input-box"
            />
          </div>
          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            <label>
              {t("Contactnummer")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details.contact_phone}
              countryCodes={true}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              // border={user.phone_number.length ? "1px solid #606734" : null}
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
          </div>
          <div
            className="col-lg-6"
            style={{ paddingRight: "40px", marginTop: "20px" }}
          >
            <label>
              {t("Adres van beheerder")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details.contact_address}
              onChange={(e) =>
                handleChangeUser("contact_address", e.target.value)
              }
              type="text"
              placeholder={t("Voer adres in")}
              className="input-box"
            />
          </div>
          {/* <div className="col-lg-6 mb-3"  style={{ paddingRight: "40px", marginTop: "20px" }}>
            <label>
              {t("PaymentGateway Mode")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <div className="radio-group" style={{display:'flex', alignItems:'center',gap:'20px'}}>
              <label style={{display:'flex', alignItems:'center',gap:'10px'}}>
                <input
                  type="radio"
                  name="paymentGatewayMode"
                  value="TEST"
                  checked={details?.paymentGatewayMode === "TEST"}
                  onChange={() =>
                    handleChangeUser("paymentGatewayMode", "TEST")
                  }
                />
                {t("Test")}
              </label>
              <label style={{display:'flex', alignItems:'center',gap:'10px'}}>
                <input
                  type="radio"
                  name="paymentGatewayMode"
                  value="LIVE"
                  checked={details?.paymentGatewayMode === "LIVE"}
                  onChange={() =>
                    handleChangeUser("paymentGatewayMode", "LIVE")
                  }
                />
                {t("Production")}
              </label>
            </div>
            {errors && errors.paymentGatewayMode ? (
              <span className="error-msg">{errors.paymentGatewayMode}</span>
            ) : null}
          </div> */}
          <div
            className="merchant-details"
            style={{
              marginTop: "50px",
              // pointerEvents: isValidMID ? "none" : "",
            }}
          >
            <h2 style={{ textAlign: "center" }}>{t("Merhchant Details")}</h2>
            {isValidMID && (
              <h5 className="success-label" style={{ textAlign: "center" }}>
                {t('Merchant details approved')}..
              </h5>
            )}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                className="col-lg-6"
                style={{
                  paddingRight: "40px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <label>
                  {t("Merchant Website Key")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <input
                  value={details.entity_website_key}
                  onChange={(e) =>
                    handleChangeUser("entity_website_key", e.target.value)
                  }
                  type="text"
                  placeholder={t("Voer Merchant Website Key In")}
                  className="input-box"
                />
              </div>
              <div
                className="col-lg-6"
                style={{
                  paddingRight: "40px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <label>
                  {t("Merchant Secret Key")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <input
                  value={details.entity_secret_key}
                  onChange={(e) =>
                    handleChangeUser("entity_secret_key", e.target.value)
                  }
                  type="text"
                  placeholder={t("Voer Merchant Secret Key in")}
                  className="input-box"
                />
              </div>
              <div
                className="col-lg-6"
                style={{
                  paddingRight: "40px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <label>
                  {t("Enter Merchant-ID")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <input
                  value={details.entity_merchant_Id}
                  onChange={(e) =>
                    handleChangeUser("entity_merchant_Id", e.target.value)
                  }
                  type="text"
                  placeholder={t("Voer Merchant Id in")}
                  className="input-box"
                />
              </div>
              <div
                className="merchant-btn"
                style={{ marginTop: "30px", flexGrow: 1 }}
              >
                <Button
                  type={
                    !details.entity_merchant_Id ||
                    !details.entity_secret_key ||
                    !details.entity_website_key
                      ? "disabled"
                      : "primary"
                  }
                  text={t("Test payment")}
                  borderRadius="66px"
                  width="25"
                  mt="4"
                  mx="auto"
                  height="60px"
                  style={{ width: "250px" }}
                  onClick={() => handlePayment()}
                />
              </div>
            </div>
          </div>

          <div className="merchant-documents" style={{ marginTop: "50px" }}>
            <h2 style={{ textAlign: "center" }}>{t("Documents")}</h2>
            <div style={{ display: "flex", flexWrap: "wrap"}}>
              <PdfUploader
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />

              <div style={{flexGrow:1,display:'flex',justifyContent:'center',marginLeft:'30px'}}>
                <Button
                  type={
                    selectedFiles.length === 0 || isUploading
                      ? "disabled"
                      : "primary"
                  }
                  text={isUploading ? t("Uploading...") : t("Upload files")}
                  borderRadius="66px"
                  width="25"
                  // mx="auto"
                  height="60px"
                  style={{ width: "250px" }}
                  onClick={handlePdfUpload}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {getRenderButton()}
      <br />
      <DefaultModal
        show={isAddedNew}
        onHide={() => dispatch(updateAddedNewFlag(false))}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => handleAddNew()}
          />
          <div className="text-center add-mosque-modal-content">
            <Icon
              iconName="congratulations"
              style={{ justifyContent: "center" }}
            />
            <div>{t("Gefeliciteerd")}</div>
            <div>
              {t(
                "We hebben de nieuwe moskee toegevoegd. U kunt op elk moment wijzigingen aanbrengen in de bestaande moskee."
              )}
            </div>
            <button onClick={() => handleAddNew()}>
              {t("Add New Mosque")}
            </button>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={isApprovedApproval}
        onHide={() => handleApproval()}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => handleApproval()}
          />
          <div className="text-center">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg" />
            <Label
              justifyContent="center"
              fontSize="20px"
              fontWeight={700}
              my="20px"
            >
              {t("Gefeliciteerd")}
            </Label>
            <Label variant="text3" my="20px" color="#7E7E7E">
              {t(
                "We hebben de nieuwe moskee toegevoegd. U kunt op elk moment wijzigingen aanbrengen in de bestaande moskee."
              )}
            </Label>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={isDecline}
        onHide={() => setIsDecline(false)}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => setIsDecline(false)}
          />
          <div className="text-center">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
            <Label
              justifyContent="center"
              fontSize="20px"
              fontWeight={700}
              my="20px"
            >
              {t("Moskee afwijzen")}
            </Label>
            <Label variant="text3" my="20px" color="#7E7E7E">
              {t(
                "U verliest permanent de details en andere informatie van deze moskee."
              )}
            </Label>
            <Box className="test" display="flex" justifyContent="center">
              <Button
                className="test"
                type="outlineColor"
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                mr="20px !important"
                onClick={() => setIsDecline(false)}
              >
                {t("Annuleren")}
              </Button>
              <Button
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                mr="20px !important"
                onClick={() => handleClickDecline()}
              >
                {t("Moskee afwijzen")}
              </Button>
            </Box>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};
export default MosqueForm;
